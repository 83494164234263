import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const MtShoya = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="The Battle of Mt. Shoya" />
        <meta
          name="twitter:image"
          content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p> The Battle of Mt. Shoya</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Sep. 30, 2020) </p>
        </div>
        <p>
          I know that{" "}
          <a
            href="http://shoya.co/shoyaright/vol1/watching-animals/"
            target="_blank"
            rel="noopener noreferrer"
          >
            last time
          </a>{" "}
          I promised to double back and fill y'all in on exactly how I was
          removed from the company at the height of Doom Summer, and how I
          eventually regained control. So, what follows is the secret history of
          the most moneyed, most consequential hostile takeover ever to take
          place.
        </p>
        <p className="highlight">Part I: The Descent</p>
        <p>
          Ard so boom, a few months ago I'm sitting in a spacious, glassy
          boardroom in an undisclosed (for now) location like I usually do: feet
          up on the table with the wool Carhartt socks in the visvim sandals,
          custom black Paradox hoodie and sweats with real blue VVS1 diamonds in
          the logos, holding a iPhone 21 Max up to my ear.
        </p>
        <p>
          The table is long and seats a dozen suits, each silhouetted with a
          leather folio open in front of em.{" "}
        </p>
        <p>
          ME: Wait, the rainforest or the company? Matter fact it don't even
          matter, buy it.
        </p>
        <p>
          I get up and move over towards the balcony, giving the ☝️ to everybody
          else in the room. I lean my hand against the glass and look through
          the window as I continue my convo. One of the suits clenches his fist
          on the table.
        </p>
        <p>ME: Unionize? What you mean like, get married?</p>
        <p>
          Next second I got a black canvas bag over my head and a tranquilizer
          in my neck smfh.
        </p>
        <p>
          So obviously from here it's a lil fuzzy for me cuz I'm going in and
          outta consciousness. The first time I really come to, they got me
          chained to a round stone table, sprawled out like the{" "}
          <a href="https://en.wikipedia.org/wiki/Vitruvian_Man">
            Vitruvian Man
          </a>
          , surrounded by a bunch of shadowy bald-head skittle diddles. They all
          chanting words from the [REDACTED––PROPRIETARY], I'm like 'aw fuck', I
          start feeling my Corporate Chakra open up, and the last thing I see
          before I black out again is a ball of grey-green-purple light rising
          outta my torso.
        </p>
        <p>
          Next time I wake up, it's loud as shit, I'm in the back of a
          four-seater propeller plane tied up neck to toe like a damn cartoon
          character. I look out the window and all I see is ocean in every
          direction before I doze off. I wake up again later on, this time long
          enough to peep the mercenaries guarding me, each of em fully outfitted
          with tactical gear and weapons by Shoya Materiel (ain't that a
          bitch?). Just before I doze off this second time, I see Christ the
          Redeemer off in the distance.
        </p>
        <p>
          Next thing I knew I was waking up in a field of grass in the middle of
          nowhere smelling real bovine. I straight got up and started walking.
        </p>
        <p>Eventually I stumbled into a humble lil bar.</p>
        <p>ME: Teléfono?</p>
        <p>
          The barkeeper points to it at the other side of the room. I go over,
          pick the receiver off the hook, and start dialing a 50-digit number
          off top (barkeeper looking at me like I'm nuts lol).
        </p>
        <p>
          While it's ringing, I'm shrugging the receiver to my ear and picking
          diamonds outta my hoodie. The call connects:
        </p>
        <p>
          ME: Oldie, it's me. I gotta get the squad back together.<br></br>
          OLDIE (THROUGH RECEIVER): ………..<br></br>
          ME: Oldie?<br></br>
          OLDIE: You sure you wanna do that…?<br></br>
          ME: Ain't no other way, Oldie!!!!!<br></br>
          Pause.<br></br>
          OLDIE: What's the word?<br></br>
          ME: Shoyariiight.<br></br>
          OLDIE: Streets is watching.<br></br>
          ME: Shoyariiight.<br></br>
          OLDIE: Never ever?<br></br>
          ME: Shoyawrong.<br></br>
        </p>
        <p>
          I hear a longer pause then a husky sigh. I know he was hoping this day
          wouldn't come.
        </p>
        <p>
          OLDIE: Imma send you whatever numbers I got. Besides that it's mostly
          addresses, you know how it is.<br></br>
          ME: One.
        </p>
        <p>I hang up the phone, put some diamonds on the bar and dip.</p>
        <p className="highlight">Part. II: Zuff Shoya & The Shoyarights</p>
        <p>– A phone rings in Dubai, UAE –</p>
        <p>
          BS: مرحبا (Hello)<br></br>
          ME: معاك أنا (It's me)<br></br>
          BS: …….هههههه (Lmaoooooo)<br></br>
          <br></br>
          Click! (I get bung on.)
        </p>
        <p>– A phone rings in Okinawa, Japan – </p>
        <p>
          S-K: もし もし (Hello)<br></br>
          ME: それは私です (It's me)<br></br>
          <br></br>
          Click! (Bung on again.)<br></br>
        </p>
        <p>– I'm at a diner in New York City, USA –</p>
        <p>
          I'm sitting at an outdoor table, wearing a medical mask and
          sunglasses, tryna look lowkey. A waiter approaches my table, wearing a
          mask and apron, carrying a pot of coffee. She pours some in a fresh
          mug and sets it in front of me.{" "}
        </p>
        <p>I take my shades off.</p>
        <p>ME: Any chance y'all accept these?</p>
        <p>I sprinkle a few diamonds on the table.</p>
        <p>
          As soon as she sees my eyes she SHATTERS the pot of hot coffee over my
          head and runs off in the middle of her shift lmfaooo.
        </p>
        <p>
          Yeah so obviously my folks wasn't feeling me and, yehmean, I don't
          blame em. But to make a long story short, about a month later I was
          able to convince most of em (I'll make formal introductions some other
          time) to help me on my mission to get the company back.{" "}
        </p>
        <p>
          We all gathered in a safehouse, plotting around a map of the Bermuda
          Triangle that shows an island where traditional maps only show water.
        </p>
        <p>
          Bout a month after that, we all get locked and loaded and jump on a
          speedboat cutting waves in the Caribbean – six highly-trained,
          highly-skilled individuals yehmean just picture that.
        </p>
        <p>
          As we get close, we see it off in the distance: Mt. Shoya, an
          artificial landmass in the shape of a mountain, made up entirely of
          skyscrapers packed tightly together, an engineering miracle, a
          megalopolis clusterfuck of money and power. Home, sweet home.
        </p>
        <p>
          By the time the coastline is in sight, three armed guards on jet skis
          are already approaching our boat. They circle it and tighten in.{" "}
        </p>
        <p>None of us are on the boat.</p>
        <p>
          I'll spare y'all the rest of the details I know a lot of y'all be
          playing video games and shit y'all know how it went. A few flash
          grenades, some AR stutters, and boom I'm back in action.
        </p>
        <p>
          Anyway y'all like that debate I put together for y'all last night? It
          was just for show though, I already picked the winner last year.{" "}
        </p>

        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020</p>
        </div>
      </div>
    </div>
  )
}
export default MtShoya
